<template>
  <a-modal
      v-drag-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1000"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <div slot="title">{{ title }}
      <a-tag :color="record.status | dictName('postStatus', 'color')" style="cursor: pointer">
        <span>{{ record.status| dictName("postStatus") }}</span>
      </a-tag>
    </div>
    <div class="tip1">
      如果帖子中存在泄露隐私的内容或其它不当内容，可以先局部调整修改内容后再审核。
    </div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="分类"
          prop="cateId"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.category"/>-->
        <a-select :options="cateList" v-model="form.cateId" v-decorator="['cateId']" @change="cateChanged"></a-select>
      </a-form-model-item>
      <a-form-model-item
          ref="title"
          label="标题"
          prop="title"
          v-bind="formItemLayout">
        <a-input
            v-model="form.title"
            allow-clear
            placeholder="输入标题"
        />
      </a-form-model-item>
      <a-form-model-item
          ref="title"
          label="是否推荐"
          prop="title"
          v-bind="formItemLayout">
        <a-radio-group v-model="form.postTop">
          <a-radio :value="1">
            置顶推荐
          </a-radio>
          <a-radio :value="0">
            不做推荐
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="内容" v-bind="formItemLayout">
        <tiny-mce-editor v-if="visible&&form.type==14" ref="editor" v-model="form.content"
                         style="width: 100%"></tiny-mce-editor>
        <a-textarea v-model="form.content" :rows="8" allClear/>
      </a-form-model-item>
      <a-form-model-item
          ref="title"
          label="媒体类型"
          prop="title"
          v-bind="formItemLayout">
        <a-radio-group v-model="form.contentMode">
          <a-radio :value="1">
            图片
          </a-radio>
          <a-radio :value="2">
            视频
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="doorHeader" label="上传图片" v-bind="formItemLayout">
        <div v-if="form.contentMode==2">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="video/mp4,video/avi,video/wmv"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'video' }"
              :headers="headers"
              :file-list="videoList"
              :show-upload-list="false"
              action="admin/upload/uploadImage"
              :before-upload="beforeUploadVideo"
              @change="(info) => handleImageChange(info,'video')"
          >
            <div v-if="videoList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传视频</div>
            </div>
            <div v-else-if="videoList[0].response" style="position: relative;padding:20px">
              <video :src="videoList[0].response.data" controls autoplay style="max-width: 200px;max-height:100px;"/>
              <a-icon type="delete" color="red" style="top:0;right:0;position: absolute" @click.stop="deleteVideo"/>
            </div>
          </a-upload>
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="coverList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="(file) => handlePreview(file,'cover')"
              @change="(info) => handleImageChange(info,'cover')"
          >
            <div v-if="coverList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传封面</div>
            </div>
          </a-upload>
        </div>
        <div v-else class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="true"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info,'image')"
          >
            <div v-if="imageList.length < 20">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <div class="tip1" style="margin:-20px 0 0 0">鼠标移动到图片上，再点击图片中的预览按钮，可以直接在线打马赛克。</div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="其他内容" v-bind="formItemLayout">
        <div style="border:1px solid #CCC;padding:10px;max-height:200px;overflow-y: auto;background-color: #F5F5F5">
          <div v-for="(val,item,index) in otherJson" :key="item" style="line-height:1.5">
            {{ item }}：{{ val }}
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div slot="footer">
      <div style="display: flex;align-items: center">
        <a-button v-if="action!='view'" type="primary" @click="auditPost" :loading="confirmLoading"
                  style="margin-left: 40px;">去审核
        </a-button>
        <div class="flex_1">&nbsp;</div>
        <a-button v-if="action!='view'" type="primary" @click="handleOk" :loading="confirmLoading"
                  style="margin-left: 40px;">立即保存
        </a-button>
        <a-button type="cancel" @click="visible=false">取消</a-button>
      </div>
    </div>

    <image-preview-modal ref="previewModal" @updateSuccess="updateSuccess"></image-preview-modal>
    <post-audit-modal ref="postAuditModal" @success="auditSuccess"></post-audit-modal>
  </a-modal>
</template>

<script>
import TinyMceEditor from "@/components/Editor/TinyMceEditor";
import ImagePreviewModal from "./ImagePreviewModal";
import PostAuditModal from "./postAuditModal";

import {getBase64OfPreview} from '@/utils/common.js';

export default {
  name: "modifyModal",
  components: {ImagePreviewModal, PostAuditModal, TinyMceEditor},
  data() {
    return {
      action: 'edit',
      formItemLayout: {
        labelCol: {span: 3},
        wrapperCol: {span: 20},
      },
      title: "",
      visible: false,
      confirmLoading: false,
      previewVisible: false,
      record: {},
      form: {},
      imageList: [],
      videoList: [],
      coverList: [],
      orgCateList: [],
      previewImage: "",
      rules: {
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
      editMode: false,
      imgEditShow: false, // 图片编辑框
      imgPath: '',//  图片编辑源
      counter: 0,
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
    otherJson() {
      return JSON.parse(this.form.otherJson || "{}");
    },
    cateList() {
      let cateType = 1;//类型（1--圈子分类，2--手册分类，3--评测分类,4--省钱宝分类）
      if (this.form.type == 14) {
        cateType = 2;
      }
      return this.orgCateList.filter(v => v.type === cateType).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    }
  },
  created() {
    this.$postJson('/category/cateList', {pageSize: 100}).then(res => {
      this.orgCateList = ((res.data && res.data.records) || [])
    })
  },
  methods: {
    handleImageChange(info, type) {
      console.log(info.file.status)
      if (type == 'cover') {
        this.coverList = info.fileList || [];
      } else if (type == 'video') {
        this.videoList = info.fileList || [];
      } else {
        this.imageList = info.fileList || [];
      }
      if (info.file.status === "uploading") {
        this.confirmLoading = true;
        return;
      }
      this.confirmLoading = false;
    },
    cateChanged(cateId) {
      let findOne = this.cateList.find(v => v.value == cateId);
      if (findOne) {
        this.form.category = findOne.label
      }
    },
    deleteVideo() {
      this.videoList = [];
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    beforeUploadVideo(file) {
      const isJpgOrPng =
          file.type === "video/mp4" ||
          file.type === "video/avi" ||
          file.type === "video/wmv";
      if (!isJpgOrPng) {
        this.$message.error("只能支持mp4/avi/wmv视频格式!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 20;
      if (!isLt2M) {
        this.$message.error("视频大小不能超过20MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file, type = 'image') {
      if (!(file.url || (file.response && file.response.data) || file.preview || file.thumbUrl)) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || (file.response && file.response.data) || file.preview || file.thumbUrl;
      this.previewVisible = true;
      this.$refs.previewModal.preview(this.previewImage, this.record, this.imageList, type)
    },
    async showModal(record, action) {
      this.visible = true;
      this.action = action || 'edit';
      this.record = record || {}
      let imageList = [];
      let videoList = [];

      if (this.action == 'edit' || this.action == 'view') {
        let {data} = await this.$get('post/info/' + (typeof record === 'object' ? record.id : record))

        this.form = {
          ...data,
        };
        //record is just post id
        this.title = "帖子ID：" + data.id;
        if (data.media) {
          imageList = JSON.parse(data.media) || [];
        }
        const that = this;
        if (this.form.contentMode == 2) {
          this.videoList = [
            {
              type: "video/avi",
              name: imageList[0].url,
              uid: imageList[0].url,
              status: "done",
              size:imageList[0].size,
              response: {data: imageList[0].url, code: 0},
            }
          ];
          this.coverList = [
            {
              type: "image/png",
              name: imageList[0].cover,
              uid: imageList[0].cover,
              status: "done",
              response: {data: imageList[0].cover, code: 0},
            }
          ];
          this.imageList = [];
        } else {
          let array = [];
          imageList.forEach((v) => {
            if (!v) return;
            let url = v;
            let path = that.$helper.getFullImgPath(url);
            array.push({
              type: "image/png",
              name: url,
              uid: url,
              status: "done",
              thumbUrl: path,
              response: {data: path, code: 0},
            });
          });
          this.imageList = array;
          this.videoList = []
          this.coverList = []
        }
        if (typeof record == 'object') {
          record.imageList = this.imageList;
        }
      } else {
        this.form = {
          ...(record || {})
        };
        this.imageList = []
        this.videoList = []
        this.coverList = []
        this.title = "新增";
      }
    },
    handleOk() {
      if (this.form.content.indexOf("data:image/") >= 0) {
        this.$message.warn("图片请选择上传方式，不要直接剪切后粘贴图片！");
        return
      }
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          let params = {
            id: this.form.id,
            cateId: this.form.cateId,
            content: this.form.content,
            title: this.form.title,
            isTop: this.form.postTop
          }
          if (this.form.contentMode == 2) {
            let video = {};
            if (this.videoList.length > 0) {
              video.url = this.videoList[0].response.data;
              video.size =  this.videoList[0].size;
            }
            if (this.coverList.length > 0) {
              video.cover = this.coverList[0].response.data;
            }

            params.media = [video]
          } else {
            params.media = this.imageList.map((item) => {
              return item.response.data;
            });
          }
          if (params.id > 0) {
            params.media = JSON.stringify(params.media);
          }

          this.$postJson(params.id > 0 ? "post/updatePost" : "post/addPost", params).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
    auditPost() {
      this.$refs.postAuditModal.showModal(this.record);
    },
    auditSuccess(passFlag) {
      if (passFlag == 1) {
        this.updateSuccess();
      }
    },

    updateSuccess() { //图片修改成功
      this.visible = false;
      this.confirmLoading = false;
      this.$emit("success");
    },
    closeDialog(newImgPath) {
      this.imgEditShow = false
      this.editMode = false;
      if (newImgPath) {
        this.$postJson('post/updateOneImg', {
          img: this.previewImage,
          newImg: newImgPath,
          postId: this.record.id
        }).then(res => {
          this.previewImage = newImgPath
          this.$message.success('图片编辑更新成功');
          this.visible = false;
          this.confirmLoading = false;
          this.$emit("success");
        })

        //修改数据
      }
    },
  }
  ,
}
;
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}

.tip1 {
  color: #FF9900;
  margin: 0px 80px 20px;
}

/deep/ .anticon-delete {
  margin-left: 20px !important;
  color: red !important;
}
</style>
